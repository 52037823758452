.spacer {
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3020px 100%;

  &--arrow-one {
    background-image: url('../../assets/img/001/colourArrows01.png');
  }

  &--arrow-two {
    background-image: url('../../assets/img/001/colourArrows02.png');
  }

  &--arrow-three {
    background-image: url('../../assets/img/001/colourArrows03.png');
  }

  &--arrow-four {
    background-image: url('../../assets/img/001/colourArrows04.png');
  }

  &--arrow-five {
    background-image: url('../../assets/img/001/colourArrows05.png');
  }

  &--arrow-six {
    background-image: url('../../assets/img/001/colourArrows06.png');
  }

  &--arrow-seven {
    background-image: url('../../assets/img/001/colourArrows07.png');
  }

  &--arrow-eight {
    background-image: url('../../assets/img/001/colourArrows08.png');
  }

  &--arrow-nine {
    background-image: url('../../assets/img/001/colourArrows09.png');
  }

  &--arrow-ten {
    background-image: url('../../assets/img/001/colourArrows10.png');
  }

  &--arrow-eleven {
    background-image: url('../../assets/img/001/colourArrows11.png');
  }

  &--arrow-twelve {
    background-image: url('../../assets/img/001/colourArrows12.png');
  }

  &--arrow-thirteen {
    background-image: url('../../assets/img/001/colourArrows13.png');
  }

  &--arrow-fourteen {
    background-image: url('../../assets/img/001/colourArrows14.png');
  }

  &--arrow-fifteen {
    background-image: url('../../assets/img/001/colourArrows15.png');
  }

  &--arrow-sixteen {
    background-image: url('../../assets/img/001/colourArrows16.png');
  }

  &--arrow-seventeen {
    background-image: url('../../assets/img/001/colourArrows17.png');
  }

  &--arrow-eighteen {
    background-image: url('../../assets/img/001/colourArrows18.png');
  }

  &--arrow-nineteen {
    background-image: url('../../assets/img/001/colourArrows19.png');
  }
}