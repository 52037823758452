@import "../../assets/sass/mixins";
@import "../../assets/sass/variables";

.adverse-modal {
  transition: .4s;

  &--show {
    top: 0 !important;
  }
  &__row {
    margin: 0 -15px;
    display: flex;
  }

  &__column {
    width: 50%;
    padding: 0 15px;
  }

  &__color-title {
    margin: 20px 0;
    font-weight: 700;
    color: #4eb9d0;
  }
}