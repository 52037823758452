@import "../../assets/sass/mixins";
@import "../../assets/sass/variables";

.footer {
  width: 100%;
  background-color: #e64f22;
  text-align: center;
  color: $white;
  font-size: 16px;

  &__link {
    display: inline-block;
    cursor: pointer;
    padding: 9px 0px;
    border: 0px;
    font-size: 16px;
    color: $white;

    &:hover {
      font-weight: 800;
    }

    &:before {
      display: block;
      content: attr(data-title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}
.nbspF {
display: inline-block;
  }