@import "../../assets/sass/mixins";
@import "../../assets/sass/variables";

.cookie-box {
  transition: all 0.4s ease-in-out;
  position: fixed;
  z-index: -10000000;
  bottom: -20px;
  left: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  background-color: #666666;
  color: #f1f1f1;
  font-size: 18px;
  padding: 40px 10px 10px;

  a {
    text-decoration: underline;
    color: $white;
  }

  &--loaded {
    opacity: 1;
    bottom: 0;
    z-index: 999999;
    
  }

  &__text {
    max-width: 900px;
    margin: auto;
    padding: 0 15px;
  }

  &__btn {
    text-transform: uppercase;
    cursor: pointer;
    background-color: #e84e0f;
    color: $white;
    padding: 10px 24px;
    font-weight: bold;
    border-radius: 4px;
    display: inline-block;
    margin: 25px;

    &:hover {
      background-color: $black;
      color: $white;
    }

    span {
      overflow-wrap: break-word;
      line-height: 24px;
    }
  }
}