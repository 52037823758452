@import "mixins";
@import "variables";

.App {

  //&.preloader {
  //  position: relative;
  //
  //  &::before {
  //    content: '';
  //    position: fixed;
  //    top: 0;
  //    left: 0;
  //    display: block;
  //    width: 100vw;
  //    height: 100vh;
  //    background-color: rgba(255, 255, 255, 0.5);
  //    z-index: 99;
  //  }
  //
  //  &::after {
  //    content: '';
  //    position: fixed;
  //    top: 50%;
  //    left: 50%;
  //    transform: translate(-50%,-50%);
  //    display: block;
  //    width: 100px;
  //    height: 100px;
  //    background-image: url('../../assets/img/preloader-flower.gif');
  //    background-repeat: no-repeat;
  //    background-position: center;
  //    background-size: contain;
  //    z-index: 99;
  //  }
  //}

  &__content {
    position: relative;

    &.mb-100 {
      @include min-from(laptopM) {
        margin-bottom: 100px;
      }
    }
  }

  &__checkout {
    position: fixed;
    top: 150%;
    transition: all .25s;
    z-index: 12;
    background: $white;

    @include min-from(laptopM) {
      width: 100%;
    }

    &.active {
      top: calc(100% - 90px);

      @include min-from(laptopM) {
        top: calc(100% - 70px);
      }

      @include min-from(desktop) {
        top: calc(100% - 90px);
      }
    }

    &.show_checkout {
      display: inline-table;
      top: 0;
      overflow-y: scroll;
      height: calc(100vh - 30px);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);

      .barBtn {
        display: none;
      }

      .cartItem {
        cursor: default;
        pointer-events: none;
        border-right: none;
      }
    }
  }
}

.fade {
  background-color: #999999 !important;
}

.resources2blocktop {
  max-width: 640pxpx !important;
}

