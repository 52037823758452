.patient-banner {
  width: 100%;
  margin: auto;
  background-image: url(../../assets/img/001/not-a-hcp-large.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 2650px;
  background-color: #d2bfd0;margin-top: -130px;
}

.pBullet ul > li:before {
  display: inline-block;
  content: "•"; 
  width: 18px;
  margin-left: -18px;
  font-size: 20px;
}

.pBullet p{

  margin-bottom: 12px;
}

